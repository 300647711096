.fm-promo-details {
  margin: 25px;
}

.fm-promo-details {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 20px 50px 60px 50px;
}

.fm-promo-details-title {
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  padding: 20px 0 40px 0;
}

.fm-promo-details-description {
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.2px;
}

.fm-promo-details-items {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.fm-promo-details-app {
  margin: 10px 10px 10px 0;
}

.fm-promo-details-app-img {
  height: 45px;
}

.fm-promo-details-terms {
  color: $light-text-color;
  font-size: 12px;
  line-height: 20px;
  margin-top: 50px;
}

.fm-promo-details-button {
  line-height: 25px;
  font-size: 15px;
  padding: 15px 20px;
  background: $text-color;
  color: #fff;
  font-weight: 600;
  letter-spacing: .2px;
  margin: 50px 0;
  cursor: pointer;
  display: inline-block;
}

@media(max-width: $breakpoint-sm) {
  .fm-promo-details-items {
    display: flex;
    flex-direction: column;
  }
}
